<template>
    <v-app>
        <v-app-bar
        app
        color="#f0f1f3"
        dense
        clipped-left
        >
            <img
            class="mr-3 my-3"
            :src="require('../../assets/logo.png')"
            height="40"
            />
            <v-toolbar-title>{{AppName}}</v-toolbar-title>
            <v-menu
                   bottom
                   rounded
                   offset-y
                 >
             </v-menu>
             <v-spacer></v-spacer>
          <UserMenu/>
        </v-app-bar>

        <v-main
        style="background:#f0f1f3"
        >
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>
        <Notification />

        <v-dialog
        v-model="loading"
        persistent
        width="300"
        >
            <v-card
            color="primary"
            dark
            >
                <v-card-text>
                    Procesando, por favor espere...
                    <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                    >
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import {mapState,mapActions} from "vuex"
    import Notification from '@/components/Snack.vue'
    import UserMenu from '@/components/layouts/UserMenu.vue'

    export default {
        name: 'App',
        data() {
            return {
                AppName: process.env.VUE_APP_NAME,
                drawer: null,
                company: null
            };
        },
        computed:{
            ...mapState(['loading','user','selectedCompany']),
            loggedUser() {
                return JSON.parse(this.user)
            },
            // sisCompanies() {
            //     return JSON.parse(this.companies)
            // }
        },
        methods: {
            ...mapActions(['setSelectedCompany']),
            selectCompany() {
                this.setSelectedCompany(this.company)
            }
        },
        watch: {
            "company": function () {
                this.company = this.selectedCompany
            }
        },
        mounted() {
            this.company = this.selectedCompany
        },
        components: {
            Notification,
            UserMenu
        }
    }
</script>
