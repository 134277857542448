<template lang="html">
	<v-snackbar
    top
    :timeout="notify.time"
    :color="notify.color"
    v-model="notify.show"
    >
        {{notify.message}}
        <template v-slot:action="{ attrs }">
            <v-btn
			color="white"
			text
			v-bind="attrs"
			@click="notify.show = false"
			v-show="notify.showClose"
            >
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
	import {mapState} from "vuex"

	export default {
		name: 'Notification',
		computed: {
			...mapState(['notify'])
		}
	}
</script>

<style lang="css" scoped>
</style>
