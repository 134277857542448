<template>
    <div>
        <v-menu
        bottom
        rounded
        offset-y
        >
            <template v-slot:activator="{ on }">
                <v-btn
                icon
                x-large
                v-on="on"
                >
                    <v-avatar
                    color="blue accent-4"
                    size="32"
                    >
                        <div style="color:#ffffff">
                            {{loggedUser.initials}}
                        </div>
                    </v-avatar>
                </v-btn>
            </template>

            <v-card>
                <v-list-item-content class="justify-center">
                    <div class="mx-auto text-center">
                        <v-avatar
                        color="blue accent-4"
                        >
                            <div style="color:#ffffff">
                                {{loggedUser.initials}}
                            </div>
                        </v-avatar>
                        <h3>{{loggedUser.name}}</h3>
                        <p class="text-caption mt-1 px-3">
                            {{loggedUser.email}}
                        </p>
                        <v-divider class="my-3"></v-divider>
                        <v-btn
                        depressed
                        rounded
                        text
                        @click="dialog = true"
                        >
                            Cambiar contraseña
                        </v-btn>
                        <br>
                        <v-btn
                        depressed
                        rounded
                        text
                        to="/logout"
                        >
                            Cerrar sesión
                        </v-btn>
                    </div>
                </v-list-item-content>
            </v-card>
        </v-menu>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                Cambiar contraseña
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-row class="pt-2">
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Contraseña"
                                            :type="inputType"
                                            v-model="$v.userPass.password.$model"
                                            :error-messages="passwordErrors"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Repetir contraseña"
                                            :type="inputType"
                                            v-model="$v.userPass.password_confirmation.$model"
                                            :error-messages="passwordConfirmationErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="lessTop">
                                        <v-col class="lessTop">
                                            <v-checkbox
                                            v-model="showPassword"
                                            label="Mostrar contraseñas"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="submit"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import {mapState,mapMutations} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required,sameAs } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        data() {
            return {
                dialog: false,
                showPassword: false,
                userPass: {
                    password: '',
                    password_confirmation: ''
                }
            }
        },
        validations: {
            userPass: {
                password: {required},
                password_confirmation: {required,sameAsPassword: sameAs('password')}
            }
        },
        computed: {
            ...mapState(['user']),
            loggedUser() {
                return JSON.parse(this.user)
            },
            passwordErrors () {
                const errors = []
                if (!this.$v.userPass.password.$dirty) return errors
                !this.$v.userPass.password.required && errors.push('Campo requerido')
                return errors
            },
            passwordConfirmationErrors () {
                const errors = []
                if (!this.$v.userPass.password_confirmation.$dirty) return errors
                !this.$v.userPass.password_confirmation.required && errors.push('Campo requerido')
                !this.$v.userPass.password_confirmation.sameAsPassword && errors.push('Las contraseñas deben coincidir')
                return errors
            },
            inputType() {
                return !this.showPassword ? "password" : "text"
            }
        },
        methods: {
            ...mapMutations(['setLayout','setNotify','setLoading']),
            submit() {
                let user = JSON.parse(this.user)
                this.$v.$touch()

                if(!this.$v.$invalid) {
                    this.setLoading(true)

                    this.axios.put('/users/passwordupdate/'+user.id,this.userPass)
                    .then( (response) => {
                        this.dialog = false
                        this.setLoading(false)
                        this.setNotify({'show':true,
                                        'color':'success',
                                        'message':response.data.message,
                                        'time':5000})

                        setTimeout(() => {
                                            this.$router.push({ name: 'Logout' })
                                        }, 3500);
                    })
                    .catch( (error) => {
                        this.setLoading(false)
                        console.log(error.response.data)
                    })
                }
            }
        },
        watch: {
            "dialog": function () {
                if(!this.dialog){
                    this.password = ''
                    this.password_confirmation = ''
                    this.$v.$reset()
                }
            }
        },
    }
</script>

<style lang="css" scoped>
    .lessTop {margin-top:-20px}
</style>
