import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        layout: "login-layout",
        token: localStorage.getItem('access_token') || null,
        settedSede: localStorage.getItem('selected_sede') || null,
        settedJornada: localStorage.getItem('selected_jornada') || null,
        settedLevel: localStorage.getItem('selected_level') || null,
        settedGrade: localStorage.getItem('selected_grade') || null,
        settedSection: localStorage.getItem('selected_section') || null,
        remember: localStorage.getItem('remember') || false,
        loading: false,
        notify: {
            show: false,
            color: 'success',
            message: '',
            time: 4000,
            showClose: false
        },
        menuItems: JSON.parse(localStorage.getItem('menuItems')) || null,
        user: localStorage.getItem('user') || null,
        // companies: localStorage.getItem('companies') || null,
        selectedCompany: localStorage.getItem('selectedCompany') || null,
        schoolCycle:localStorage.getItem('schoolCycle') || null,
    },
    getters:{
        loggedIn(state){
            return state.token !== null
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        // setCompanies(state, companies) {
        //     state.companies = companies
        // },
        setSchoolCycle(state,cycle) {
            state.schoolCycle = cycle
        },
        setSelectedCompany(state, selectedCompany) {
            state.selectedCompany = selectedCompany
        },
        setLayout(state, newLayout) {
            state.layout = newLayout
        },
        setLoading(state,loading){
            state.loading = loading
        },
        setNotify(state,notify){
            state.notify = notify
        },
        setSelectedFilters(state,selectedFilters){
            state.settedSede = selectedFilters.sede
            state.settedJornada = selectedFilters.jornada
            state.settedLevel = selectedFilters.level
            state.settedGrade = selectedFilters.grade
            state.settedSection = selectedFilters.section
        },
        unsetSelectedFilters(state){
            state.settedSede = null
            state.settedJornada = null
            state.settedLevel = null
            state.settedGrade = null
            state.settedSection = null
        },
        setRemember(state,remember){
            state.remember = remember
        },
        retrieveToken(state, token){
            state.token = token
        },
        destroyToken(state){
            state.token = null
        },
        setLoginParam(state,status){
            if(status){
                localStorage.setItem('is_logged',status)
            }else{
                localStorage.removeItem('is_logged')
            }
        },
        setMenuItems(state,menu){
            state.menuItems = menu
        },
    },
    actions: {
        setSchoolCycle(context,cycle) {
            context.commit('setSchoolCycle',cycle)
            localStorage.setItem('schoolCycle',cycle)
        },
        setSelectedFilters(context,selectedFilters){
            context.commit('setSelectedFilters',selectedFilters)

            localStorage.setItem('selected_sede',selectedFilters.sede)
            localStorage.setItem('selected_jornada',selectedFilters.jornada)
            localStorage.setItem('selected_level',selectedFilters.level)
            localStorage.setItem('selected_grade',selectedFilters.grade)
            localStorage.setItem('selected_section',selectedFilters.section)
        },
        unsetSelectedFilters(context){
            context.commit('unsetSelectedFilters')

            localStorage.removeItem('selected_sede')
            localStorage.removeItem('selected_jornada')
            localStorage.removeItem('selected_level')
            localStorage.removeItem('selected_grade')
            localStorage.removeItem('selected_section')
        },
        setRemember(context,remember){
            context.commit('setRemember',remember)
            localStorage.setItem('remember',remember)
        },
        unsetRemember(context,remember){
            context.commit('setRemember',remember)
            localStorage.setItem('remember',remember)
            // localStorage.removeItem('remember')
        },
        retrieveToken(context, credentials){
            return new Promise((resolve,reject) => {
                axios.post('/login',{
                    username: credentials.username,
                    password: credentials.password
                })
                .then((response) => {
                    const token = response.data.access_token
                    const menu = response.data.menu

                    localStorage.setItem('access_token', token)
                    context.commit('retrieveToken', token)
                    localStorage.setItem('permissions',JSON.stringify(response.data.permissions))
                    context.commit('setMenuItems', menu)
                    localStorage.setItem('menuItems', JSON.stringify(menu))
                    context.commit('setUser', JSON.stringify(response.data.user))
                    localStorage.setItem('user', JSON.stringify(response.data.user))
                    // context.commit('setCompanies', JSON.stringify(response.data.companies))
                    // localStorage.setItem('companies', JSON.stringify(response.data.companies))
                    // context.commit('setSelectedCompany', 1)
                    // localStorage.setItem('selectedCompany',1)

                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            })
        },
        setSelectedCompany(context,company){
            context.commit('setSelectedCompany',company)
            localStorage.setItem('selectedCompany',company)
        },
        setUser(context,user) {
            context.commit('setUser', JSON.stringify(user))
            localStorage.setItem('user', JSON.stringify(user))
        },
        destroyToken(context){
            if(context.getters.loggedIn){
                return new Promise((resolve,reject) => {
                    axios.post('/logout')
                    .then((response) => {
                        localStorage.removeItem('permissions')
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('menuItems')
                        localStorage.removeItem('user')
                        // localStorage.removeItem('companies')
                        context.commit('destroyToken')
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                })
            }
        },
    },
    modules: {
    }
})
