<template>
    <v-app>
        <Sidebar/>

        <v-app-bar
        app
        color="white"
        dense
        clipped-left
        >
            <img
            class="mr-3 my-3"
            :src="require('../../assets/logo.png')"
            height="35"
            />
            <!-- <v-toolbar-title>{{AppName}}</v-toolbar-title> -->
            <v-menu
                   bottom
                   rounded
                   offset-y
                 >
             </v-menu>
             <!-- <v-spacer></v-spacer> -->
             <!-- <v-select
             class="ml-4 mt-3"
             :items="sisCompanies"
             item-text="name"
             item-value="id"
             v-model="company"
             @change="setSelectedCompany"
             >
             </v-select> -->

             <v-row>
                 <v-col
                 cols="3"
                 >
                    <v-select
                    label="Ciclo"
                    :items="schoolCycles"
                    item-value="id"
                    class="ml-2 mt-6"
                    solo
                    dense
                    v-model="selectedCycle"
                    ></v-select>
                 </v-col>
             </v-row>

          <v-spacer></v-spacer>

          <UserMenu/>
        </v-app-bar>

        <v-main
        style="background:#f0f1f3"
        >
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>
        <Notification />

        <v-dialog
        v-model="loading"
        persistent
        width="300"
        >
            <v-card
            color="primary"
            dark
            >
                <v-card-text>
                    Procesando, por favor espere...
                    <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                    >
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import {mapState,mapActions} from "vuex"
    import Notification from '@/components/Snack.vue'
    import Sidebar from '@/components/layouts/Sidebar.vue'
    import UserMenu from '@/components/layouts/UserMenu.vue'

    export default {
        name: 'App',
        data() {
            return {
                AppName: process.env.VUE_APP_NAME,
                drawer: null,
                company: null,
                selectedCycle: null
            };
        },
        computed:{
            ...mapState(['loading','user','selectedCompany','schoolCycle']),
            loggedUser() {
                return JSON.parse(this.user)
            },
            // sisCompanies() {
            //     return JSON.parse(this.companies)
            // },
            schoolCycles() {
                let cycles = Array()
                let firstYear = 2020
                let actualYear = this.moment().format("Y")

                while(firstYear <= (actualYear)) {
                    cycles.push(firstYear)
                    firstYear++
                }

                return cycles
            }
        },
        methods: {
            ...mapActions(['setSelectedCompany','setSchoolCycle']),
            selectCompany() {
                this.setSelectedCompany(this.company)
            },
            setLocalCycle() {
                this.selectedCycle = parseInt(this.schoolCycle)
            }
        },
        watch: {
            "company": function () {
                this.company = this.selectedCompany
            },
            "selectedCycle": function () {
                this.setSchoolCycle(this.selectedCycle)
            }
        },
        mounted() {
            this.company = this.selectedCompany
            this.setLocalCycle()
        },
        components: {
            Notification,
            Sidebar,
            UserMenu
        }
    }
</script>
