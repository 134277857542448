<template lang="html">
    <v-app>
        <v-main>
            <v-container fill-height fluid
            class="indigo"
            >
                <router-view />
            </v-container>
        </v-main>

        <Notification />

        <v-dialog
        v-model="loading"
        persistent
        width="300"
        >
            <v-card
            color="primary"
            dark
            >
                <v-card-text>
                    Procesando, por favor espere...
                    <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                    >
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import {mapState} from "vuex"
import Notification from '@/components/Snack.vue'

export default {
    computed: {
        ...mapState(['loading'])
    },
    components: {
            Notification
        }
}
</script>

