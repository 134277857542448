<template>
    <component :is="layout"></component>
</template>

<script>
    import LoginLayout from '@/components/layouts/Login.vue'
    import MainLayout from '@/components/layouts/Main.vue'
    import ParentLayout from '@/components/layouts/ParentMain.vue'

    import {mapState} from 'vuex'

    export default {
        computed: {
            ...mapState(['layout'])
        },
        components: {
            LoginLayout,
            MainLayout,
            ParentLayout
        }
    }
</script>
