import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import moment from 'moment'

Vue.prototype.moment = moment

Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
// localStorage.removeItem('access_token')
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.config.productionTip = process.env.VUE_APP_PRODUCTION_TIP

Vue.axios.interceptors.request.use(
    config => {
        const token = store.state.token

        if (token) {
            config.headers = Object.assign({
              Authorization: `Bearer ${token}`
            }, config.headers);
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

Vue.axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },

    error => {
        if (error.response) {
            switch (error.response.status) {
                case 400:

                //do something
                break;

                case 401:
                    console.log("session expired");
                    localStorage.removeItem('access_token')
                    store.commit('destroyToken')

                    if(router.currentRoute.name !== 'Login'){
                        router.push({ name: 'Login' })
                    }

                    console.log(router.currentRoute.name)
                break;

                case 403:
                    localStorage.removeItem('access_token')
                    store.commit('destroyToken')
                    console.log('FORBBIDEN')
                    router.push({ name: 'Login' })
                break;

                // case 404:
                //     alert('page not exist');
                // break;

                case 502:
                    setTimeout(() => {
                        router.push({ name: 'Login' })
                    }, 1000);
                break;
            }

            return Promise.reject(error.response);
        }
    }
);

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            next({
                name: 'Login',
            })
        } else {
            if (to.meta.permissions && to.meta.permissions.length > 0) {
                let user = JSON.parse(localStorage.getItem('user'))

                if(user.first_login_at == null) {
                    return next('/firstlogin/'+user.id)
                }else{
                    let userPermissions = JSON.parse(localStorage.getItem('permissions'))
                    let isAllowed = userPermissions.some(p => to.meta.permissions.includes(p))

                    if (!isAllowed) {
                        if(user.name_roles.includes('Padre')) {
                            return next('/parenthome')
                        }else{
                            return next('/home')
                        }
                    }
                }
            }

            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters.loggedIn) {
            next({
                name: 'Students',
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

Vue.directive('can', {
  bind: function (el, binding, vnode) {
    if (!JSON.parse(localStorage.getItem('permissions'))
        .includes(binding.value
          .replace(/'/g, "")
            .replace(/"/g, ""))) {
       vnode.elm.style.display = "none";
    }
  }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
