<template>
    <v-navigation-drawer
    app
    color="blue accent-4"
    permanent
    expand-on-hover
    clipped
    >
        <v-list
        dense
        nav
        color="blue accent-4"
        dark
        >
            <v-list-group
            :prepend-icon="item.icon"
            color="white"
            dark
            no-action
            v-for="(item,index) in filteredMenuItems"
            :key="index"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                link exact :to="{ name: subitem.route }"
                v-can="subitem.permission"
                v-for="(subitem,subindex) in item.sub_options"
                :key="subindex"
                >
                    <v-list-item-content>
                        <v-list-item-title v-text="subitem.name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                        <v-icon>{{subitem.icon}}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        data() {
            return {
                items: []
            }
        },
        computed: {
            ...mapState(['menuItems']),
            filteredMenuItems() {
                let menu = this.menuItems.filter((item) => {
                    return item.sub_options.length > 0
                });

                return menu
            }
        },
        methods: {
            get() {
                this.axios.get('/menu')
                .then( (response) => {
                    console.log(response)
                    this.items = response.data.options
                })
                .catch( (error) => {
                    console.log(error)
                })
            }
        },
        created() {
            // this.get();
        }
    }
</script>
